import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import loadingPlugin, { ExtraModelsFromLoading } from '@rematch/loading';
import persist from '@rematch/persist';
import storage from 'redux-persist/lib/storage'
import { models, IRootModel } from './models';

type FullModel = ExtraModelsFromLoading<IRootModel, { type: 'full' }>;

export const store = init<IRootModel, FullModel>({
  models,
  plugins: [
    loadingPlugin({ type: 'full' }),
    persist({
			key: 'persist-storage',
			storage,
			whitelist: ['calls'],
		}),
  ]
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<IRootModel>;
export type RootState = RematchRootState<IRootModel, FullModel>;
