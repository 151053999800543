import React from "react";
import { useNavigate } from "react-router";
import { Outlet, useLocation } from "react-router-dom";
import { AppBar, IconButton, Toolbar } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SettingsIcon from "@mui/icons-material/Settings";
import ReloadPrompt from "./ReloadPrompt";
import "./App.css";

function App() {
  const location = useLocation();
  const router = useNavigate();

  return (
    <main className="App">
      <AppBar position="static">
        <Toolbar>
          {location.pathname !== "/" ? (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              onClick={() => router("/")}
            >
              <ChevronLeftIcon />
            </IconButton>
          ) : null}
          <div style={{ flexGrow: 1 }}></div>
          {location.pathname === "/" ? (
            <IconButton
              size="large"
              color="inherit"
              onClick={() => router("/settings")}
            >
              <SettingsIcon />
            </IconButton>
          ) : null}
        </Toolbar>
      </AppBar>
      <Outlet />
      <ReloadPrompt />
    </main>
  );
}

export default App;
