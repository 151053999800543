import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import App from './App'
import Home from './pages/Home'
import Settings from './pages/Settings'
import { Provider } from 'react-redux'
import { store } from './store'
import './index.css'

createRoot(document.getElementById('app')!).render(
  <BrowserRouter>
    <Provider store={store}>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />}/>
          <Route path="/settings" element={<Settings />}/>
        </Route>
      </Routes>
    </Provider>
  </BrowserRouter>,
)
