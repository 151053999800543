import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Button, IconButton, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Dispatch, RootState } from "../store";
import "./Settings.css";
import { CallItemType } from "../utils/types";

function Settings({
  callList,
  addCallItem,
  updateCallItem,
  removeCallItem,
}: {
  callList: CallItemType[];
  addCallItem: (item: CallItemType) => void;
  updateCallItem: (item: CallItemType) => void;
  removeCallItem: (id: string) => void;
}) {
  const handleChangeText = (callItem: any, key: string) => (ev: any) => {
    callItem[key] = ev.target.value;
    updateCallItem(callItem);
  };

  const handleAddNewItem = () => {
    addCallItem({
      id: `item-${new Date().getTime()}`,
      text: "",
      url: "",
    });
  };

  const handleDeleteCItem = (callItem: CallItemType) => {
    removeCallItem(callItem.id);
  };

  return (
    <div className="settings-page">
      {callList.map((callItem: CallItemType, index: number) => (
        <div
          className="settings-inputs-item"
          key={index}
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
            borderBottom: "1px solid #ececec"
          }}
        >
          <TextField
            fullWidth
            label="Text"
            variant="outlined"
            value={callItem.text}
            onChange={handleChangeText(callItem, "text")}
            sx={{ marginBottom: 1 }}
            size="small"
          />
          <TextField
            fullWidth
            label="URL"
            variant="outlined"
            value={callItem.url}
            onChange={handleChangeText(callItem, "url")}
            size="small"
          />
          <div style={{ marginLeft: "auto", textAlign: "right" }}>
            <IconButton
              size="small"
              onClick={() => handleDeleteCItem(callItem)}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      ))}

      <Button
        variant="contained"
        onClick={handleAddNewItem}
        sx={{ margin: 0 }}
        fullWidth
      >
        Add New
      </Button>
    </div>
  );
}

const mapState = (state: RootState) => ({
  callList: state.calls.callList,
});

const mapDispatch = (dispatch: Dispatch) => ({
  addCallItem: dispatch.calls.addCallItem,
  updateCallItem: dispatch.calls.updateCallItem,
  removeCallItem: dispatch.calls.removeCallItem,
});

export default connect(mapState, mapDispatch)(Settings);
