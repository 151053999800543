import { createModel } from '@rematch/core';
import { IRootModel } from '.';
import { CallItemType } from '../utils/types';

export type CallsType = {
    callList: CallItemType[]
}

const InitialCallsState: CallsType = {
    callList: []
}

export const calls = createModel<IRootModel>()({
    state: InitialCallsState,
    reducers: {
        addCallItem(state, payload: CallItemType) {
            return {...state, callList: state.callList.concat([payload])}
        },
        updateCallItem(state, payload: CallItemType) {

            return {...state, callList: state.callList.map((callItem: CallItemType) => {
                if(callItem.id === payload.id) {
                    callItem = payload
                }
                return callItem
            })}
        },
        removeCallItem(state, payload: string) {
            return {...state, callList: state.callList.filter((callItem: CallItemType) => {
                if(callItem.id !== payload) {
                    return callItem
                }
            })}
        }
    }
})