import React, { useState } from "react";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import iziToast from 'izitoast';
import { RootState } from "../store";
import { CallItemType, CallResponseType } from "../utils/types";
import { validURL } from "../utils/validation";
import 'izitoast/dist/css/iziToast.min.css';
import "./Home.css";

iziToast.settings({
  position: 'bottomLeft',
  maxWidth: 300
});

function Home() {
  const [callResults, setCallResults] = useState<CallResponseType[]>([]);
  const { callList } = useSelector((rootState: RootState) => rootState.calls);

  const handleRequestCall = async (callItem: CallItemType) => {
    if (validURL(callItem.url)) {
      const res = await axios.get(callItem.url);
      if (
        callResults.some(
          (cRItem: CallResponseType) => cRItem.id === callItem.id
        )
      ) {
        setCallResults(
          callResults.map((cRItem: CallResponseType) => {
            if (cRItem.id === callItem.id) {
              cRItem.result = res.data;
            }
            return cRItem;
          })
        );
      } else {
        setCallResults(
          callResults.concat([
            {
              id: callItem.id,
              result: res.data,
            },
          ])
        );
      }
    } else {
      iziToast.error({
        title: 'Failure',
        message: 'URL is invalid'
      })
    }
  };

  return (
    <div className="Home">
      {callList.length ? callList.map((callItem: CallItemType, index: number) => (
        <div key={index}>
          <Button
            variant="contained"
            disabled={callItem.text && callItem.url ? false : true}
            fullWidth
            sx={{ marginBottom: '10px' }}
            onClick={() => handleRequestCall(callItem)}
          >
            {callItem.text ? callItem.text : "No Title"}
          </Button>
          <div>
            {callResults.some(
              (cRItem: CallResponseType) => cRItem.id === callItem.id
            )
              ? typeof callResults.find(
                    (cRItem: CallResponseType) => cRItem.id === callItem.id
                  )?.result === 'string'
                
                ? callResults.find(
                  (cRItem: CallResponseType) => cRItem.id === callItem.id
                )?.result : JSON.stringify(callResults.find(
                  (cRItem: CallResponseType) => cRItem.id === callItem.id
                )?.result)
              : null}
          </div>
        </div>
      )) : "No Content"}
    </div>
  );
}

export default Home;
